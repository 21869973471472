export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '2',
    tagVariant: 'light-warning',
    route: 'dashboard',
    // children: [
    //   {
    //     title: 'Drive Access',
    //     route: 'drive-access',
    //   },
    //   // {
    //   //   title: 'eCommerce',
    //   //   route: 'dashboard-ecommerce',
    //   // },
    //   // {
    //   //   title: 'Analytics',
    //   //   route: 'dashboard-analytics',
    //   // },
    // ],
  },
]
